import React from "react";
import { Close } from "@material-ui/icons";
import { Modal, Fade, Backdrop } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { detectIncognito } from "detectincognitojs";

import LocalStorage from "../utils/localStorage";
import { showAuthError, returnCurrentLink } from "../utils/validations";
import useStyles from "./styles/authDialogStyles";
import { Store } from "../hooks/main_store";
import AuthService from "../services/auth";
import { authService } from "../services/firebase";
import * as Routes from "../utils/routes";
import DialogForm from "./dialogForm";
import Model from "../hooks/Model";
import _ from "lodash";

const AuthDialog = ({ open = false, onClose = () => {} }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { state } = React.useContext(Store);

  const [vc, setVc] = React.useState({
    showPassword: false,
    email: "",
    password: "",
    showRegister: false,
    showPrompt: false,
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const changeInput = (name) => (event) => {
    event.persist();
    setVc((oldValues) => ({
      ...oldValues,
      [name]: event.target.value,
    }));
  };

  const login = async () => {
    try {
      Model.setData("opacity", 0.7);
      Model.setData("loading", true);
      if (!vc.email /*|| !vc.password*/) {
        throw new Error(t("components.authDialog.mustFillAllFields"));
      } else {
        const emailLower = vc.email.toLowerCase().replace(/ /g, "");
        await AuthService.emailFirebaseLogin(emailLower, vc.password);
      }
      onClose();
      Model.setData("loading", false);
    } catch (e) {
      Model.setData("loading", false);
      Model.updateAlerts({
        message: showAuthError(_.get(e, "message", t("commons.unknowError"))),
        variant: "error",
      });
    }
  };

  const loginWithLink = async () => {
    try {
      Model.setData("opacity", 0.7);
      Model.setData("loading", true);
      if (!vc.email) {
        throw new Error(t("components.authDialog.mustFillAllFields"));
      } else {
        const emailLower = vc.email.toLowerCase().replace(/ /g, "");
        const result = await detectIncognito();

        /* To review and improve */
        const urlParams = new URLSearchParams(window.location.search);
        const uidPartner = urlParams.get("ref");
        const parentUrl = urlParams.get("parentUrl");

        const response = await AuthService.sendSignInLinkToEmail({
          email: emailLower,
          partner: state.isOnPluginPartner && uidPartner ? uidPartner : "",
          url:
            state.isOnPluginPartner && uidPartner
              ? parentUrl
              : window.location.origin + "/dashboard",
        });

        if (response.ok) {
          LocalStorage(result.isPrivate).setItem("emailForSignIn", emailLower);

          onClose();

          Model.setData("dialog", {
            open: true,
            title: t("components.authDialog.sentLink"),
            text: t("components.authDialog.reviewLinkYourInbox", {
              email: emailLower,
            }),
            txtRight: t("commons.ok"),
            fnRight: () => {
              Model.setData("dialog", { open: false });
            },
          });
        } else {
          throw response.data;
        }
      }
      Model.setData("loading", false);
    } catch (e) {
      Model.setData("loading", false);
      Model.updateAlerts({
        message: showAuthError(e?.message || e),
        variant: "error",
      });
    }
  };

  const verifyAuthLink = async (emailString) => {
    let avoidHideModal = false;

    try {
      Model.setData("loading", true);

      if (authService.isSignInWithEmailLink(window.location.href)) {
        const result = await detectIncognito();
        let email =
          emailString ||
          LocalStorage(result.isPrivate).getItem("emailForSignIn");

        if (!email) {
          Model.setData("showAuth", true);
          changeVc({ showPrompt: true, email: "" });
          avoidHideModal = true;

          return Model.updateAlerts({
            message: t("components.authDialog.pleaseEnterEmail"),
            variant: "warning",
          });
        }

        await authService.signInWithEmailLink(email, window.location.href);

        LocalStorage(result.isPrivate).removeItem("emailForSignIn");
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      if (!avoidHideModal) {
        Model.setData("showAuth", false);
      }
    }
  };

  const register = async () => {
    try {
      Model.setData("opacity", 0.7);
      Model.setData("loading", true);
      if (!vc.email || !vc.password) {
        throw new Error(t("components.authDialog.mustFillAllFields"));
      } else {
        const emailLower = vc.email.toLowerCase().replace(/ /g, "");
        await AuthService.emailFirebaseRegister(emailLower, vc.password);
        await AuthService.sendEmailVerification();
      }
      onClose();
      Model.setData("loading", false);
      Model.updateAlerts({
        message: t("components.authDialog.reviewYourInbox"),
        variant: "warning",
      });
    } catch (e) {
      Model.setData("loading", false);
      Model.updateAlerts({
        message: showAuthError(_.get(e, "message", t("commons.unknowError"))),
        variant: "error",
      });
    }
  };

  const onSubmit = async () => {
    if (vc.showRegister) {
      register();
    } else {
      login();
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onSubmit();
    }
  };

  const onVerifyEmailForm = async () => {
    try {
      Model.setData("opacity", 0.7);
      Model.setData("loading", true);
      if (!vc.email) {
        throw new Error(t("components.authDialog.mustFillAllFields"));
      } else {
        const emailLower = vc.email.toLowerCase().replace(/ /g, "");
        verifyAuthLink(emailLower);
      }
      changeVc({ showPrompt: false, email: "" });
    } catch (e) {
      Model.setData("loading", false);
      Model.updateAlerts({
        message: showAuthError(e),
        variant: "error",
      });
    }
  };

  React.useEffect(() => {
    setVc({
      showPassword: false,
      email: "",
      password: "",
      showRegister: false,
    });
  }, [open]);

  React.useEffect(() => {
    if (state.uid) {
      onClose();
    }
    //eslint-disable-next-line
  }, [state.uid]);

  React.useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged((user) => {
      if (!user && window.location.href.includes("oobCode")) {
        verifyAuthLink();
      }
    });

    return () => unsubscribe();
    //eslint-disable-next-line
  }, []);

  if (state.loading) {
    return <></>;
  }

  return (
    <Modal
      className={styles.contModal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div className={styles.contForm}>
          <div className={styles.rowTitle}>
            <p className={styles.title}>{t("components.authDialog.logIn")}</p>
            <button className={styles.closeBtn} onClick={onClose}>
              <Close />
            </button>
          </div>
          <div className={styles.contInputs}>
            <input
              className={styles.input}
              placeholder={t("commons.email")}
              type="email"
              onChange={changeInput("email")}
              value={vc.email}
              maxLength={320}
              autoFocus
              onKeyDown={onKeyDown}
            />
            <div className={styles.termsLbl}>
              {t("components.authDialog.onClickAccept")}{" "}
              <a
                href={returnCurrentLink(
                  Routes.RENDER_LEGAL_DOC + "?document=terms"
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("components.authDialog.useConditions")}
              </a>
              , {t("components.authDialog.the")}{" "}
              <a
                href={returnCurrentLink(
                  Routes.RENDER_LEGAL_DOC + "?document=privacy"
                )}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t("components.authDialog.privacyPolicy")}
              </a>
              , {t("components.authDialog.receiveNotifications")}
            </div>
            <button className={styles.fullBtn} onClick={loginWithLink}>
              {vc.showRegister
                ? t("components.authDialog.createAccount")
                : t("components.authDialog.enterAccount")}
            </button>
          </div>

          <DialogForm
            show={vc.showPrompt && !state.loading}
            title={t("components.authDialog.enterAccount")}
            text={t("components.authDialog.pleaseEnterEmail")}
            leftBtText={t("commons.cancel")}
            funcLeft={() => changeVc({ showPrompt: false, email: "" })}
            rightBtText={t("commons.accept")}
            funcRight={onVerifyEmailForm}
            inputs={[
              { label: t("commons.email"), value: "email", type: "email" },
            ]}
            vc={vc}
            changeVc={changeVc}
          />
        </div>
      </Fade>
    </Modal>
  );

  // return (
  //   <Modal
  //     className={styles.contModal}
  //     open={open}
  //     onClose={onClose}
  //     closeAfterTransition
  //     BackdropComponent={Backdrop}
  //     BackdropProps={{ timeout: 500 }}
  //   >
  //     <Fade in={open}>
  //       <div className={styles.contForm}>
  //         <div className={styles.rowTitle}>
  //           <p className={styles.title}>
  //             {vc.showRegister
  //               ? t("components.authDialog.register")
  //               : t("components.authDialog.logIn")}
  //           </p>
  //           <button className={styles.closeBtn} onClick={onClose}>
  //             <Close />
  //           </button>
  //         </div>
  //         <div className={styles.contInputs}>
  //           <input
  //             className={styles.input}
  //             placeholder={t("commons.email")}
  //             type="email"
  //             onChange={changeInput("email")}
  //             value={vc.email}
  //             maxLength={320}
  //             autoFocus
  //             onKeyDown={onKeyDown}
  //           />
  //           <div className={styles.rowPass}>
  //             <input
  //               className={[styles.input, styles.inputPass].join(" ")}
  //               placeholder={t("commons.password")}
  //               type={vc.showPassword ? "text" : "password"}
  //               onChange={changeInput("password")}
  //               value={vc.password}
  //               maxLength={50}
  //               onKeyDown={onKeyDown}
  //             />
  //             <button
  //               className={styles.eyeBtn}
  //               onClick={() =>
  //                 setVc((ov) => ({ ...ov, showPassword: !ov.showPassword }))
  //               }
  //             >
  //               {vc.showPassword ? <VisibilityOff /> : <Visibility />}
  //             </button>
  //           </div>
  //           {vc.showRegister ? (
  //             <div className={styles.termsLbl}>
  //               {t("components.authDialog.onClickAccept")}{" "}
  //               <a
  //                 href={returnCurrentLink(
  //                   Routes.RENDER_LEGAL_DOC + "?document=terms"
  //                 )}
  //                 target="_blank"
  //                 rel="noopener noreferrer"
  //               >
  //                 {t("components.authDialog.useConditions")}
  //               </a>
  //               , {t("components.authDialog.the")}{" "}
  //               <a
  //                 href={returnCurrentLink(
  //                   Routes.RENDER_LEGAL_DOC + "?document=privacy"
  //                 )}
  //                 rel="noopener noreferrer"
  //                 target="_blank"
  //               >
  //                 {t("components.authDialog.privacyPolicy")}
  //               </a>
  //               , {t("components.authDialog.receiveNotifications")}
  //             </div>
  //           ) : (
  //             <button
  //               className={styles.noBgBtn}
  //               onClick={() => changeVc({ showPrompt: true })}
  //             >
  //               {t("components.authDialog.forgotPassword")}
  //             </button>
  //           )}
  //           <button className={styles.fullBtn} onClick={onSubmit}>
  //             {vc.showRegister
  //               ? t("components.authDialog.createAccount")
  //               : t("components.authDialog.enterAccount")}
  //           </button>
  //         </div>
  //         <div className={styles.footerModal}>
  //           <button className={styles.noBgBtn} onClick={() => changeForm()}>
  //             {vc.showRegister
  //               ? t("components.authDialog.alreadyHaveAccount")
  //               : t("components.authDialog.createNewAccount")}
  //           </button>
  //         </div>
  //         <DialogForm
  //           show={vc.showPrompt}
  //           title={t("components.authDialog.recoverPassword")}
  //           text={t("components.authDialog.enterEmailSendLink")}
  //           leftBtText={t("commons.cancel")}
  //           funcLeft={() => changeVc({ showPrompt: false, email: "" })}
  //           rightBtText={t("commons.accept")}
  //           funcRight={onSendFormRecover}
  //           inputs={[
  //             { label: t("commons.email"), value: "email", type: "email" },
  //           ]}
  //           vc={vc}
  //           changeVc={changeVc}
  //         />
  //       </div>
  //     </Fade>
  //   </Modal>
  // );
};

export default AuthDialog;
