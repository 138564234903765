/* eslint-disable */
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CheckCircle } from "@material-ui/icons";
import EventsService from "../../services/events";
import { Store } from "../../hooks/main_store";
import pageStyles from "./styles";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";

const NequiLoading = ({ history }) => {
  const styles = pageStyles();
  const [show, setShow] = React.useState(false);
  const [showDone, setShowDone] = React.useState(false);
  const [callback, setCallback] = React.useState(null);
  const { state } = React.useContext(Store);

  const runDoneTransition = (status) => {
    Model.setData("nequiTransactionId", "");

    if (status === "35") {
      setShowDone(true);

      setTimeout(() => {
        history.push(
          `${Routes.FINISH_PAYMENT}?nequi_transaction=${state.nequiTransactionId}`
        );
      }, 3000);
    } else {
      setTimeout(() => {
        history.push(
          `${Routes.FINISH_PAYMENT}?nequi_transaction=${state.nequiTransactionId}`
        );
      }, 500);
    }
  };

  const verifyTransaction = async () => {
    try {
      const response = await EventsService.verifyNequiTransaction(
        state.nequiTransactionId
      );

      if (response.status !== "33") {
        runDoneTransition(response.status);
      }
    } catch (e) {
      Model.updateAlerts({
        message: e?.message || String(e),
        variant: "error",
      });
    }
  };

  React.useEffect(() => {
    if (show) {
      setCallback(setInterval(verifyTransaction, 10000));
    } else {
      clearInterval(callback);
    }
    return () => clearInterval(callback);
  }, [show]);

  React.useEffect(() => {
    if (state.nequiTransactionId) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [state.nequiTransactionId]);

  return (show || showDone) && !state.loading && !state.superLoading ? (
    <div className={styles.modal}>
      <div className={styles.card}>
        <div className={styles.spinner}>
          {showDone ? (
            <CheckCircle fontSize="large" />
          ) : (
            <CircularProgress size={42} />
          )}
        </div>
        <div className={styles.info}>
          <p className={styles.title}>
            {showDone
              ? "¡Hecho! Aprobación completa"
              : "Estamos esperando a Nequi"}
          </p>
          <p className={styles.description}>
            {showDone
              ? "Ya hemos confirmado tu compra, estamos terminando los últimos detalles de tu compra para redirigirte a tu siguiente paso"
              : "Ingresa a Nequi, ve a notificaciones y confirma tu compra. Estaremos esperando la confirmación de la transacción. No cierres esta ventana"}
          </p>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default NequiLoading;
