import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Store } from "../hooks/main_store";
import Model from "../hooks/Model";
import PropTypes from "prop-types";
import pageStyles from "./styles/dialogStyles";
import _ from "lodash";

const DialogComponent = () => {
  const { state } = React.useContext(Store);
  const handleClose = _.get(state, "dialog.onClose", () => {});
  const styles = pageStyles();

  const renderLink = () => {
    const subtext = _.get(state, "dialog.text", "");
    const linktext = _.get(state, "dialog.link", "");
    const startIndex = subtext.indexOf("[[");
    const endIndex = subtext.indexOf("]]");
    const linkText = subtext.slice(startIndex + 2, endIndex);
    const leftText = subtext.slice(0, startIndex);
    const rightText = subtext.slice(endIndex + 2);

    if (startIndex > -1 && linktext) {
      return (
        <span>
          {leftText}
          <a href={linktext} target="blank">
            {linkText}
          </a>
          {rightText}
        </span>
      );
    }

    return subtext;
  };

  return (
    <Dialog
      open={_.get(state, "dialog.open", false)}
      onClose={() => {
        if (!_.get(state, "dialog.disableBackdropClick", false)) {
          Model.setData("dialog", { open: false });
        }
        handleClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: styles.container }}
    >
      <DialogTitle id="alert-dialog-title">
        {_.get(state, "dialog.title", "")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {renderLink()}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {state?.dialog?.txtAux ? (
          <Button
            onClick={() => {
              const fnAux = _.get(state, "dialog.fnAux", () => {});
              fnAux();
            }}
            color="primary"
            className={styles.buttonAux}
          >
            {_.get(state, "dialog.txtAux", "")}
          </Button>
        ) : null}
        {state?.dialog?.txtLeft ? (
          <Button
            onClick={() => {
              const fnLeft = _.get(state, "dialog.fnLeft", () => {});
              fnLeft();
            }}
            color="primary"
            className={styles.buttonCancel}
          >
            {_.get(state, "dialog.txtLeft", "")}
          </Button>
        ) : null}
        <Button
          onClick={() => {
            const fnRight = _.get(state, "dialog.fnRight", () => {});
            fnRight();
          }}
          color="primary"
          className={styles.buttonDone}
        >
          {_.get(state, "dialog.txtRight", "")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogComponent.defaultProps = {
  customStyles: "",
};

DialogComponent.propTypes = {
  customStyles: PropTypes.string,
};

export default DialogComponent;
