import moment from "moment";
import _ from "lodash";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendEmailVerification,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updateEmail,
  updatePassword,
  sendPasswordResetEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  query,
  where,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  runTransaction,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  getDatabase,
  ref as refDatabase,
  get,
  child,
  onValue,
  runTransaction as runTransactionDatabase,
} from "firebase/database";

import i18next from "../languages/i18n";

const firebaseConfig =
  process.env.NODE_ENV === "development" ||
  window?.location?.host?.includes?.("test.")
    ? {
        apiKey: "AIzaSyBWijl6LBRseXB2BlAT67M9ri_4XY20EQY",
        authDomain: "fravents-test.firebaseapp.com",
        databaseURL: "https://fravents-test-default-rtdb.firebaseio.com",
        projectId: "fravents-test",
        storageBucket: "fravents-test.appspot.com",
        messagingSenderId: "760107598292",
        appId: "1:760107598292:web:09775c627a35c95a7d5641",
        measurementId: "G-KNCZB1SD2Q",
      }
    : {
        apiKey: "AIzaSyC6Tp8r0HRn9gcXKnHPqvgK2qIADAiN8bE",
        authDomain: "fravents.firebaseapp.com",
        databaseURL: "https://fravents-default-rtdb.firebaseio.com",
        projectId: "fravents",
        storageBucket: "fravents.appspot.com",
        messagingSenderId: "753393299084",
        appId: "1:753393299084:web:5014abfa282f424d8b55a9",
        measurementId: "G-T1L1GYQR04",
      };

const app = initializeApp(firebaseConfig);

// Analytics
const analytics = getAnalytics(app);
const analyticsService = {
  logEvent: (eventName) => logEvent(analytics, eventName),
};

// Authentication
const actionCodeSettings = {
  url: window.location.href,
  handleCodeInApp: true,
};
const auth = getAuth(app);
const authService = {
  createUserWithEmailAndPassword: (email, password) =>
    createUserWithEmailAndPassword(auth, email, password),
  signInWithEmailAndPassword: (email, password) =>
    signInWithEmailAndPassword(auth, email, password),
  onAuthStateChanged: (observer) => onAuthStateChanged(auth, observer),
  signOut: () => signOut(auth),
  sendEmailVerification: () => sendEmailVerification(getAuth(app).currentUser),
  currentUser: () => getAuth(app).currentUser,
  reauthenticate: (email, password) =>
    reauthenticateWithCredential(
      getAuth(app).currentUser,
      EmailAuthProvider.credential(email, password)
    ),
  updateEmail: (email) => updateEmail(getAuth(app).currentUser, email),
  updatePassword: (password) =>
    updatePassword(getAuth(app).currentUser, password),
  sendPasswordResetEmail: (email) => sendPasswordResetEmail(auth, email),
  isSignInWithEmailLink: (url) => isSignInWithEmailLink(auth, url),
  signInWithEmailLink: (email, url) => signInWithEmailLink(auth, email, url),
  sendSignInLinkToEmail: (email) =>
    sendSignInLinkToEmail(auth, email, actionCodeSettings),
};

// Firestore
const firestore = getFirestore(app);
const firestoreService = {
  setDoc: (refCollection, refDocument, data) =>
    setDoc(doc(firestore, refCollection, refDocument), data),
  updateDoc: (refCollection, refDocument, data) =>
    updateDoc(doc(firestore, refCollection, refDocument), data),
  getDoc: (refCollection, refDocument) =>
    getDoc(doc(firestore, refCollection, refDocument)),
  getCollection: (refCollection) =>
    getDocs(collection(firestore, refCollection)),
  getQuery: (refCollection, queries = []) => {
    const returnQueries = () =>
      queries.map((queryData) =>
        where(queryData.label, queryData.condition, queryData.value)
      );
    return getDocs(
      query(collection(firestore, refCollection), ...returnQueries())
    );
  },
  updateBookmarks: (uid, newData) =>
    new Promise(async (resolve, reject) => {
      try {
        await runTransaction(firestore, async (transaction) => {
          const sfDocRef = doc(firestore, "users", uid);
          const sfDoc = await transaction.get(sfDocRef);
          if (!sfDoc.exists()) {
            throw new Error(i18next.t("services.firebase.infoDidntBeFound"));
          }
          let currentData = sfDoc.data().bookmarks || [];
          const indexSearch = _.findIndex(currentData, { uid: newData });
          if (indexSearch > -1) {
            currentData.splice(indexSearch, 1);
          } else {
            currentData.push({
              uid: newData,
              created: moment().format("x"),
            });
          }
          transaction.update(sfDocRef, { bookmarks: currentData });
          resolve();
        });
      } catch (e) {
        reject(e);
      }
    }),
  updateWishProducts: (uid, newData) =>
    new Promise(async (resolve, reject) => {
      try {
        await runTransaction(firestore, async (transaction) => {
          const sfDocRef = doc(firestore, "users", uid);
          const sfDoc = await transaction.get(sfDocRef);
          if (!sfDoc.exists()) {
            throw new Error(i18next.t("services.firebase.infoDidntBeFound"));
          }
          let currentData = sfDoc.data().wishProducts || [];
          const indexSearch = _.findIndex(currentData, { uid: newData });
          if (indexSearch > -1) {
            currentData.splice(indexSearch, 1);
          } else {
            currentData.push({
              uid: newData,
              created: moment().format("x"),
            });
          }
          transaction.update(sfDocRef, { wishProducts: currentData });
          resolve(currentData);
        });
      } catch (e) {
        reject(e);
      }
    }),
};

// Storage
const storage = getStorage(app);
const storageService = {
  uploadImage: (url, file) => {
    const fileColection = ref(storage, url);
    return uploadBytes(fileColection, file);
  },
  getUrlImage: (url) => {
    const fileColection = ref(storage, url);
    return getDownloadURL(fileColection);
  },
};

// Realt time Databse
const database = getDatabase(app);
const databaseService = {
  getDatabase: (url) => {
    const dbRef = refDatabase(database);
    return get(child(dbRef, url));
  },
  onDatabase: (url, callback) => {
    const dbRef = refDatabase(database);
    const listener = onValue(
      child(dbRef, url),
      (snapshot) => {
        callback(snapshot.val());
      },
      (snapshot) => {
        console.log(i18next.t("services.firebase.errorListenerChat"), snapshot);
      }
    );

    if (listener?.val?.()) {
      return listener;
    }

    return listener;
  },
  setChatroomDatabase: (url, data) => {
    const dbRef = refDatabase(database);

    return runTransactionDatabase(child(dbRef, url), (post) => {
      if (post) {
        if (post?.data?.length > 0) {
          post.data.push(data);
        } else {
          post.data = [data];
        }
      }
      return post;
    });
  },
};

export {
  authService,
  firestoreService,
  storageService,
  analyticsService,
  databaseService,
};
