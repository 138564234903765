import React from "react";
import {
  Menu as MenuIcon,
  Error,
  // Dashboard,
  Security,
  Storefront,
  ShoppingCart,
  CompareArrows,
  CropFree,
  Person,
} from "@material-ui/icons";
import { Menu, MenuItem, ListItemText, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Drawer } from "@material-ui/core";

import ShoppingCartDialog from "../ShoppingCartDialog";
import Avatar from "../../assets/images/avatar.jpeg";
import { Store } from "../../hooks/main_store";
import AuthService from "../../services/auth";
import * as Routes from "../../utils/routes";
import AuthDialog from "../authDialog";
import Model from "../../hooks/Model";
import pageStyles from "./styles";

const Header = ({ history }) => {
  const { t } = useTranslation();
  const styles = pageStyles();
  const { state } = React.useContext(Store);
  const { userInfo, uid } = state;
  const pathname = history?.location?.pathname;
  const partnerLogo = state?.infoPartner?.logo;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedAnchor, setSelectedAnchor] = React.useState(null);
  const [vc, setVc] = React.useState({
    showMenu: false,
    showAuth: false,
    showCart: false,
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const goTo = (route, anchor, event) => {
    if (anchor?.children?.length > 0) {
      setAnchorEl(event.currentTarget);
      setSelectedAnchor(anchor);
    } else if (!uid && route === Routes.MY_TRANSACTIONS) {
      changeVc({ showAuth: true });
    } else if (route === Routes.ADMIN_SHOP && !userInfo?.availableShop) {
      Model.setData("dialog", {
        open: true,
        title: t("components.header.titleWantSell"),
        text: t("components.header.descriptionWantSell"),
        txtRight: t("commons.ready"),
        fnRight: () => {
          Model.setData("dialog", { open: false });
        },
      });
    } else if (uid && route === "openQrScan") {
      changeVc({ showMenu: false });
      Model.setData("showQrScanModal", true);
    } else {
      history.push(route);
    }
  };

  const BarSelected = ({ show = false }) => {
    return show ? <div className={styles.barSelected}></div> : null;
  };

  const renderUser = () =>
    uid ? (
      <div className={styles.contImgProfile}>
        <img
          src={userInfo?.imageProfile || Avatar}
          alt="profile"
          className={styles.imageProfile}
        />
        {state?.emailVerified ? null : (
          <div className={styles.contIconError}>
            <Error className={styles.iconError} />
          </div>
        )}
      </div>
    ) : (
      <div className={styles.btLogin}>{t("components.header.logIn")}</div>
    );

  const renderAnchors = () => {
    const anchors = [
      // {
      //   label: t("components.header.events"),
      //   view: state?.isWhiteLabel
      //     ? `/${
      //         state.isOnPluginPartner ? "pluginPartner" : "partnerProfile"
      //       }?ref=${state?.infoPartner?.uid}`
      //     : Routes.ALL_EVENTS,
      //   icon: <Dashboard />,
      // },
    ];

    if (uid) {
      anchors.push({
        label: t("components.header.myShopping"),
        view: Routes.MY_TRANSACTIONS,
        icon: <CompareArrows />,
      });
    }

    anchors.push({
      label: t("components.header.profile"),
      view: Routes.PROFILE,
      icon: <Person />,
      onlyMobile: true,
    });

    if (userInfo?.isPartner && !state?.isWhiteLabel) {
      // anchors.push({
      //   label: "Administrador",
      //   view: Routes.ADMIN,
      //   icon: <Security />,
      // });
      anchors.push({
        label: t("components.header.admin"),
        view: Routes.ADMIN,
        icon: <Security />,
        children: [
          {
            label: t("components.header.myEvents"),
            view: Routes.ADMIN,
          },
          {
            label: t("components.header.shop"),
            view: Routes.ADMIN_SHOP,
          },
        ],
      });
    }

    if (!uid && state?.isWhiteLabel) {
      anchors.splice(1, 1);
    }

    if (uid) {
      anchors.push({
        label: t("components.header.scanQr"),
        view: "openQrScan",
        icon: <CropFree />,
        onlyMobile: true,
      });
    }

    return anchors.map((anchor, index) => {
      const isOnView =
        pathname === anchor.view ||
        anchor?.children?.filter?.((child) => child?.view === pathname)
          ?.length > 0;

      return (
        <React.Fragment key={"item-anchor-" + index}>
          <button
            className={[
              styles.anchorBtn,
              isOnView ? styles.textTheme : "",
              anchor?.children?.length > 0 ? styles.anchorWithChild : "",
              anchor?.onlyMobile ? styles.onlyMobile : "",
            ].join(" ")}
            onClick={(e) => goTo(anchor.view, anchor, e)}
          >
            <span>{anchor.label}</span>
            {anchor.icon}
            <BarSelected show={isOnView} />
          </button>
          {anchor?.children?.map((childAnchor, childIndex) => (
            <button
              key={"item-child-anchor-" + childIndex}
              className={[
                styles.anchorBtn,
                styles.childAnchorBtn,
                isOnView ? styles.textTheme : "",
              ].join(" ")}
              onClick={(e) => goTo(childAnchor.view, childAnchor, e)}
            >
              <span>{childAnchor.label}</span>
              {anchor.icon}
            </button>
          ))}
        </React.Fragment>
      );
    });
  };

  React.useEffect(() => {
    Model.setData("emailVerified", AuthService.currentUser()?.emailVerified);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuthService.currentUser()?.emailVerified]);

  React.useEffect(() => {
    if (state.openAuth) {
      Model.setData("openAuth", false);
      changeVc({ showAuth: true });
    }
  }, [state.openAuth]);

  if (state.isOnPluginPartner) {
    return (
      <AuthDialog
        open={vc.showAuth}
        onClose={() => changeVc({ showAuth: false })}
      />
    );
  }

  return (
    <>
      <div className={styles.contHeader}>
        <div className={styles.background} />
        {state?.isWhiteLabel ? null : (
          <div
            className={[
              styles.contTitle,
              partnerLogo ? styles.logoFloat : "",
            ].join(" ")}
            onClick={() => history.push(Routes.DASHBOARD)}
          >
            <img
              alt="Fravents"
              src={require("../../assets/images/Logo-Fravents-blanco.svg")}
              className={styles.contLogoTitle}
            />
            <BarSelected show={pathname === "/dashboard"} />
          </div>
        )}
        {partnerLogo ? (
          <div
            className={[
              styles.contLogoParnter,
              state?.isWhiteLabel ? styles.alignToleft : {},
            ].join(" ")}
          >
            <img
              alt="parter"
              src={partnerLogo}
              className={styles.logoPartner}
            />
          </div>
        ) : (
          <div className={styles.contAnchorsMenu}>{renderAnchors()}</div>
        )}
        {state?.isWhiteLabel && partnerLogo ? (
          <div className={styles.contAnchorsMenu}>{renderAnchors()}</div>
        ) : null}
        <div className={styles.rowInHeader}>
          {uid ? (
            <button
              className={[styles.cart, styles.hideMobile].join(" ")}
              onClick={() => Model.setData("showQrScanModal", true)}
            >
              <CropFree />
            </button>
          ) : null}
          <button
            className={styles.cart}
            onClick={() =>
              uid
                ? changeVc({ showCart: !vc.showCart })
                : changeVc({ showAuth: true })
            }
          >
            <ShoppingCart />
            {!state.shoppingCart?.length ? null : (
              <div className={styles.badgeCart}>
                {state.shoppingCart
                  .map((item) => item?.quantity || 0)
                  .reduce(
                    (total, current) => parseInt(total) + parseInt(current)
                  )}
              </div>
            )}
          </button>
          <Tooltip
            title={t("components.header.verifyHere")}
            open={!!(state?.uid && !state?.emailVerified)}
            classes={{ popper: styles.verifyHereAlert }}
          >
            <button
              className={styles.userInfo}
              onClick={() =>
                uid ? goTo(Routes.PROFILE) : changeVc({ showAuth: true })
              }
            >
              {renderUser()}
            </button>
          </Tooltip>
        </div>
        <button
          className={styles.contMenuMobile}
          onClick={() =>
            changeVc({ showMenu: true, showAuth: false, showCart: false })
          }
        >
          <MenuIcon />
        </button>
        <ShoppingCartDialog
          open={vc.showCart}
          onClose={() => changeVc({ showCart: false })}
          history={history}
        />
      </div>

      <Drawer
        anchor="right"
        open={vc.showMenu}
        onClose={() => changeVc({ showMenu: false })}
        className={styles.drawerContainer}
      >
        <button
          className={styles.closeMenuBtn}
          onClick={() => changeVc({ showMenu: false })}
        >
          <span>
            {t("components.header.hi")}{" "}
            {userInfo?.name?.length > 9
              ? `${(userInfo?.name || "").slice(0, 10)} ...`
              : userInfo?.name || ""}{" "}
            {"👋"}
          </span>
          {uid ? renderUser() : null}
        </button>
        <div className={styles.contAnchors}>{renderAnchors()}</div>
        {state?.isWhiteLabel ? null : (
          <>
            <button
              className={styles.anchorBtn}
              onClick={() => goTo(Routes.CONTACT_PUBLISH)}
            >
              <span>{t("components.header.publishEvent")}</span>
              <Storefront />
            </button>
            <div className={styles.footerMenu}>
              Fravents © 2025 - v. {state.version}
            </div>
          </>
        )}
      </Drawer>
      <AuthDialog
        open={vc.showAuth}
        onClose={() => changeVc({ showAuth: false })}
      />
      <Menu
        elevation={3}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          setSelectedAnchor(null);
        }}
      >
        {selectedAnchor?.children?.map?.((action) => (
          <MenuItem
            key={action.label}
            onClick={(e) => goTo(action.view, action, e)}
          >
            <ListItemText primary={action.label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Header;
