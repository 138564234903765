import React, { useState } from "react";
import { Check, Cancel, AccessTime } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import pageStyles from "./styles/finishpaymentStyles";
import EventsService from "../../services/events";
import Model from "../../hooks/Model";
import { Store } from "../../hooks/main_store";

const Finishpayment = ({ history }) => {
  const { state } = React.useContext(Store);
  const { t } = useTranslation();
  const styles = pageStyles();
  const [status, setStatus] = useState("pending");
  const [redirectPartnerUrl, setRedirectPartnerUrl] = useState("");
  const [vc, setVc] = React.useState({
    statusLbl: "",
    messageStatus: "",
    external_reference: "",
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const firstLoad = async () => {
    try {
      Model.setData("loading", true);
      const urlParams = new URLSearchParams(window.location.search);
      const payment_id = urlParams.get("payment_id");
      const external_reference = urlParams.get("external_reference");
      const nequi_transaction = urlParams.get("nequi_transaction");

      if (nequi_transaction) {
        const response = await EventsService.verifyNequiTransaction(
          nequi_transaction
        );

        if (response?.status === "35") {
          setStatus("approved");
          changeVc({
            statusLbl:
              t("pages.events.finishpayment.finishedTransaction") +
              nequi_transaction,
            messageStatus: t("pages.events.finishpayment.thanksBuy"),
            nequi_transaction,
          });
        } else if (response?.status === "33") {
          setStatus("pending");
          changeVc({
            statusLbl:
              t("pages.events.finishpayment.pendingTransaction") +
              nequi_transaction,
            messageStatus: t("pages.events.finishpayment.weAreProcessing"),
          });
          setTimeout(firstLoad, 60000); // Verify payment again
        } else {
          setStatus("rejected");
          changeVc({
            statusLbl:
              t("pages.events.finishpayment.rejectedTransaction") +
              nequi_transaction,
            messageStatus: t("pages.events.finishpayment.sorryNoComplete"),
          });
        }

        setRedirectPartnerUrl(response?.redirectPartnerUrl || "");
      } else if (payment_id) {
        const response = await EventsService.getPaymentStatus({
          id: payment_id,
          topic: "payment",
          external_reference,
        });
        if (response?.status === "approved") {
          setStatus("approved");
          changeVc({
            statusLbl:
              t("pages.events.finishpayment.finishedTransaction") +
              external_reference,
            messageStatus: t("pages.events.finishpayment.thanksBuy"),
            external_reference,
          });
        } else if (
          response?.status === "pending" ||
          response?.status === "authorized" ||
          response?.status === "in_process"
        ) {
          setStatus("pending");
          changeVc({
            statusLbl:
              t("pages.events.finishpayment.pendingTransaction") +
              external_reference,
            messageStatus: t("pages.events.finishpayment.weAreProcessing"),
          });
          setTimeout(firstLoad, 60000); // Verify payment again
        } else {
          setStatus("rejected");
          changeVc({
            statusLbl:
              t("pages.events.finishpayment.rejectedTransaction") +
              external_reference,
            messageStatus: t("pages.events.finishpayment.sorryNoComplete"),
          });
        }

        setRedirectPartnerUrl(response?.redirectPartnerUrl || "");
      } else {
        setStatus("rejected");
        Model.updateAlerts({
          message: t("pages.events.finishpayment.noFoundPayment"),
          variant: "error",
        });
        history.push("/");
      }
    } catch (e) {
      setStatus("rejected");
      Model.updateAlerts({
        message: `${t("pages.events.finishpayment.paymentError")} ${String(e)}`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  React.useEffect(() => {
    firstLoad();
    return () => {};
    //eslint-disable-next-line
  }, []);

  return state.loading ? (
    <></>
  ) : (
    <div className={styles.container}>
      <div className={styles.iconTop}>
        {status === "approved" ? (
          <Check />
        ) : status === "pending" ? (
          <AccessTime />
        ) : (
          <Cancel />
        )}
      </div>
      <p className={styles.title}>{vc.statusLbl}</p>
      <div className={styles.contCard}>{vc.messageStatus}</div>
      {redirectPartnerUrl ? (
        <button
          className={[styles.detailsBtn, styles.confirmBtn].join(" ")}
          onClick={() => (window.location.href = redirectPartnerUrl)}
        >
          Retornar al comercio
        </button>
      ) : (
        <button
          className={[styles.detailsBtn, styles.confirmBtn].join(" ")}
          onClick={() => history.push("/")}
        >
          {t("pages.events.finishpayment.returnFravents")}
        </button>
      )}
    </div>
  );
};

export default Finishpayment;
