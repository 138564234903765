import React from "react";
import { useTranslation } from "react-i18next";
import { Policy } from "@material-ui/icons";

import pageStyles from "./styles";

const IncognitoAlert = () => {
  const { t } = useTranslation();

  const styles = pageStyles();

  return (
    <div className={styles.container}>
      <Policy className={styles.icon} />
      <p className={styles.message}>
        {t("components.incognitoAlert.forLiveExp")}{" "}
        <b>{t("components.incognitoAlert.noUseIncognito")}</b>
      </p>
      <img
        alt="Fravents"
        src={require("../../assets/images/Logo-Fravents-blanco.svg")}
        className={styles.logo}
      />
    </div>
  );
};

export default IncognitoAlert;
