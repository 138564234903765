import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";
import moment from "moment";

import {
  Link,
  InfoOutlined,
  ChevronLeft,
  ChevronRight,
} from "@material-ui/icons";
import {
  currencyFormat,
  returnTax,
  verifyEmail,
  isBookmarked,
  returnCurrentLink,
} from "../../utils/validations";
import Avatar from "../../assets/images/avatar.jpeg";
import MercadoPagoScript from "../MercadoPagoScript";
import EventsService from "../../services/events";
import { Store } from "../../hooks/main_store";
import { keyMp } from "../../utils/constants";
import * as Routes from "../../utils/routes";
import DialogForm from "../dialogForm";
import Model from "../../hooks/Model";
import ItemEvent from "../ItemEvent";
import pageStyles from "./styles";

const ContentEvent = ({
  eventInfo = {},
  artists = [],
  contacts = [],
  isMobile = false,
  sells = {},
  scrollYvalue = 0,
  getSellings = () => {},
  onBook = () => {},
  history,
  isPreview = false,
  hideSellOffers,
}) => {
  const { t } = useTranslation();
  const { state } = React.useContext(Store);
  const styles = pageStyles();
  const initialValues = {
    showPrompt: false,
    email: state?.userInfo?.email,
    name: "",
    numId: "",
    lastName: "",
  };
  const sellContainerRef = React.useRef(null);
  const [vc, setVc] = React.useState({ ...initialValues, interested: false });
  const [idsPreferences, setIdsPreferences] = React.useState([
    // "1063707439-1b4a79a4-dffc-489a-b233-d02719bebb37",
    // "1063707439-5934fc66-bf9f-4e59-890f-714a336a2691",
  ]);
  const [mercadoPago, setMercadoPago] = React.useState(null);
  const [showFloatSellLabel, setShowFloatSellLabel] = React.useState(true);
  const [quantityPriceSell, setQuantityPriceSell] = React.useState({});
  const [wantsSeeEasteregg, setWantsSeeEasteregg] = React.useState(false);
  const [clickOnInfo, setClickOnInfo] = React.useState({
    quantity: 0,
    lastClickAt: null,
  });

  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const coords =
    typeof eventInfo?.coords === "string"
      ? {
          lat: parseFloat(eventInfo?.coords?.split?.(",")?.[0]),
          lon: parseFloat(eventInfo?.coords?.split?.(",")?.[1]),
        }
      : eventInfo?.coords;

  const clickOnInfoIcon = () => {
    setWantsSeeEasteregg(false);
    setClickOnInfo((ov) => {
      const nowMoment = moment();
      const newValue = ov.quantity + 1;

      if (newValue === 22) {
        Model.updateAlerts({
          message: "Don't cry, you're perfect / JMFC 11-02-2023 ❤",
          variant: "success",
          handleClose: () => setWantsSeeEasteregg(true),
        });
      }

      if (
        ov.lastClickAt &&
        nowMoment.diff(moment(ov.lastClickAt, "x"), "seconds") < 1
      ) {
        return {
          quantity: newValue,
          lastClickAt: nowMoment.format("x"),
        };
      }

      return { quantity: 1, lastClickAt: nowMoment.format("x") };
    });
  };

  const changeQuantityPriceSell = (price, addition = 0, limit = 0) => {
    setQuantityPriceSell((ov) => {
      const newValues = ov ? { ...ov } : {};

      newValues[price] = newValues[price]
        ? newValues[price] + addition
        : addition > 0
        ? addition
        : 0;

      newValues[price] = newValues[price] > limit ? limit : newValues[price];
      newValues[price] = newValues[price] < 1 ? 0 : newValues[price];

      return newValues;
    });
  };

  const returnWithMobileStyles = (normalStyles) =>
    [normalStyles, isMobile ? "isMobile" : ""].join(" ");

  const renderContacts = () =>
    contacts.map((contact, index) => (
      <div
        key={"contact-" + index}
        className={styles.contArtist}
        onClick={() => {
          window.open(
            contact?.phone
              ? "https://wa.me/" + contact?.phone?.replace(/[^0-9]/g, "")
              : "#",
            contact?.phone ? "_blank" : "_self"
          );
        }}
      >
        <div
          className={styles.imgArtist}
          style={{
            backgroundImage: `url(${
              contact?.imageProfile || eventInfo?.logo || Avatar
            })`,
          }}
        />
        <p className={styles.nameArtist}>{contact?.name}</p>
        <p className={styles.subArtist}>{contact?.phone || "-"}</p>
      </div>
    ));

  const renderArtists = () =>
    artists.map((artist, index) => (
      <div
        key={"artist-" + index}
        className={styles.contArtist}
        onClick={() =>
          artist?.link ? window?.open?.(artist?.link, "blank") : null
        }
      >
        <div
          className={styles.imgArtist}
          style={{
            backgroundImage: `url(${
              artist?.image || eventInfo?.logo || Avatar
            })`,
          }}
        >
          {artist?.link ? (
            <div className={[styles.overArtist, "overArtist"].join(" ")}>
              <Link />
            </div>
          ) : null}
        </div>
        <p className={styles.nameArtist}>{artist?.name}</p>
        {artist?.link ? (
          <p className={styles.subArtist}>
            {t("components.contentEvent.viewWebsite")}
          </p>
        ) : null}
        {/* <p className={styles.subArtist}>{artist?.subtitle || "-"}</p> */}
      </div>
    ));

  const renderPrices = () => {
    const eventsArray =
      eventInfo?.prices?.length > 1
        ? eventInfo?.prices?.filter(
            (data) => data?.price || data?.priceUsd !== 0
          )
        : eventInfo?.prices;
    return eventsArray
      ?.filter((price) => !price?.hide || price?.forceAvailable)
      ?.map?.((price, index) => {
        const showCop = price?.price >= 0 && eventInfo.acceptCop;
        const showUsd = price?.usdPrice >= 0 && eventInfo.acceptUsd;

        return (
          <div key={price.label + String(index)} className={styles.rowPrice}>
            <div
              className={styles.labelPrice}
              style={price?.color ? { backgroundColor: price.color } : {}}
            >
              {price.label}
            </div>
            {showCop &&
            (state.defaultCurrency === "COP" ||
              isPreview ||
              (state.defaultCurrency === "USD" && !showUsd)) ? (
              <div className={styles.amountPrice}>
                {currencyFormat(price.price, "COP", 0)}
                <br />
                <span>COP</span>
              </div>
            ) : null}
            {showUsd &&
            (state.defaultCurrency === "USD" ||
              isPreview ||
              (state.defaultCurrency === "COP" && !showCop)) ? (
              <div className={styles.amountPrice}>
                {currencyFormat(price.usdPrice, "COP", 2)}
                <br />
                <span>USD</span>
              </div>
            ) : null}
          </div>
        );
      });
  };

  const confirmSell = async () => {
    try {
      Model.setData("loading", true);
      Model.setData("dialog", { open: false });

      const isTest = process.env.NODE_ENV === "development" ? "yes" : null;

      const emailLower = vc.email.toLowerCase().replace(/ /g, "");
      const data = {
        isTest,
        name: vc.name,
        lastName: vc.lastName,
        email: emailLower,
        numId: vc.numId,
        eventSelected: eventInfo?.uid,
        selection: quantityPriceSell,
        sells,
        currency: state.defaultCurrency,
      };

      const response = await EventsService.confirmSell(data);

      if (
        !response?.idsPreferences ||
        (response.idsPreferences?.length || 0) < 1 ||
        !mercadoPago
      ) {
        throw new Error(t("components.contentEvent.cannotBook"));
      } else if (response.idsPreferences?.length > 1) {
        setQuantityPriceSell({});

        if (getSellings) {
          getSellings();
        }

        Model.setData("dialog", {
          open: true,
          title: t("components.contentEvent.multiplePayments"),
          text: t("components.contentEvent.pendingMultiplePayments", {
            length: response.idsPreferences?.length,
          }),
          txtLeft: t("commons.cancel"),
          fnLeft: () => {
            Model.setData("dialog", { open: false });
          },
          txtRight: t("commons.agree"),
          fnRight: () => {
            setIdsPreferences(
              response.idsPreferences
                .map((idValue) => idValue?.value || idValue)
                .filter((idValue) => !!idValue)
            );
            Model.setData("dialog", { open: false });
          },
        });
      } else {
        setQuantityPriceSell({});

        // Go to Pay
        if (state.defaultCurrency === "USD") {
          window.open(
            `https://pay.cryptomus.com/pay/${
              response.idsPreferences[0]?.value || response.idsPreferences[0]
            }`,
            "_self"
          );
        } else {
          setTimeout(() => {
            mercadoPago.checkout({
              preference: {
                id:
                  response.idsPreferences[0]?.value ||
                  response.idsPreferences[0],
              },
              autoOpen: true,
            });
          }, 1000);
        }
      }
    } catch (e) {
      Model.updateAlerts({
        message: e?.message || String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const openTransaction = (index) => {
    if (idsPreferences[index] && mercadoPago) {
      if (state.defaultCurrency === "USD") {
        window.open(
          `https://pay.cryptomus.com/pay/${
            idsPreferences[index]?.value || idsPreferences[index]
          }`,
          "_blank"
        );
      } else if (mercadoPago) {
        const checkout = mercadoPago.checkout({
          preference: {
            id: idsPreferences[index]?.value || idsPreferences[index],
          },
        });
        checkout.open();
      }
    } else {
      Model.updateAlerts({
        message: t("components.contentEvent.transationNoAvailable"),
        variant: "error",
      });
    }
  };

  const showConfirmDialog = () => {
    let quantity = 0;
    Object.keys(quantityPriceSell).forEach(
      (keyPrice) => (quantity += quantityPriceSell[keyPrice] || 0)
    );

    changeVc({ showPrompt: false });
    Model.setData("dialog", {
      open: true,
      title: t("components.contentEvent.confirmSelection"),
      text: t("components.contentEvent.confirmPayResell", {
        name: vc.name,
        quantity,
        multiples: quantity > 1 ? "s" : "",
      }),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yesConfirm"),
      fnRight: confirmSell,
    });
  };

  const onSendForm = () => {
    if (!vc.name || !vc.lastName || !vc.numId || !vc.email) {
      Model.updateAlerts({
        message: t("components.contentEvent.completeDataTry"),
        variant: "error",
      });
    } else if (!verifyEmail(vc.email)) {
      Model.updateAlerts({
        message: t("components.contentEvent.enteredEmailBadFormat"),
        variant: "error",
      });
    } else {
      showConfirmDialog();
    }
  };

  const returnTotalSell = () => {
    let totalSell = 0;
    let taxes = 0;

    const textSells = Object.keys(quantityPriceSell)
      .filter((keyPrice) => quantityPriceSell[keyPrice] > 0)
      .map((keyPrice) => {
        totalSell +=
          (parseFloat(keyPrice) +
            returnTax(keyPrice, "", state.defaultCurrency)) *
          quantityPriceSell[keyPrice];

        taxes +=
          returnTax(keyPrice, "", state.defaultCurrency) *
          quantityPriceSell[keyPrice];

        return `\n${currencyFormat(
          keyPrice,
          "COP",
          state.defaultCurrency === "USD" ? 2 : 0
        )} - x${quantityPriceSell[keyPrice]} ticket${
          quantityPriceSell[keyPrice] === 1 ? "" : "s"
        } `;
      });

    return { totalSell, textSells, taxes };
  };

  const returnTotalCommission = () => {
    const { taxes } = returnTotalSell();

    if (state.defaultCurrency === "USD") {
      return currencyFormat(parseFloat(taxes).toFixed(2), "COP", 2);
    }

    return currencyFormat(Math.ceil(parseFloat(taxes)), "COP", 0);
  };

  const returnCurrenyTotalSells = () => {
    const { totalSell } = returnTotalSell();

    if (state.defaultCurrency === "USD") {
      return currencyFormat(parseFloat(totalSell).toFixed(2), "COP", 2);
    }

    return currencyFormat(Math.ceil(parseFloat(totalSell)), "COP", 0);
  };

  const onSellTickets = () => {
    const { textSells } = returnTotalSell();

    changeVc(initialValues);

    if (textSells.length < 1) {
      return Model.updateAlerts({
        message: t("components.contentEvent.mustSelectOffer"),
        variant: "warning",
      });
    }

    if (!state?.uid) {
      return Model.setData("openAuth", true);
    }

    Model.setData("dialog", {
      open: true,
      title: t("components.contentEvent.beforeContinue"),
      text: t("components.contentEvent.agreeBuyResell", {
        textSells,
        totalSells: returnCurrenyTotalSells(),
      }),
      link: returnCurrentLink(Routes.RENDER_LEGAL_DOC + "?document=resale"),
      txtLeft: t("commons.cancel"),
      fnLeft: () => {
        Model.setData("dialog", { open: false });
      },
      txtRight: t("commons.imAgree"),
      fnRight: () => {
        Model.setData("dialog", { open: false });
        changeVc({ showPrompt: true, email: state?.userInfo?.email });
      },
    });
  };

  const getResaleProcess = async (sells = []) => {
    try {
      Model.setData("loading", true);
      const response = await EventsService.returnIdsPreferencesTickets({
        ticketsIds: sells.map((sell) => sell.refBookedResale),
      });

      if (mercadoPago && response?.length > 0) {
        setIdsPreferences(response);
      }
    } catch (e) {
      Model.updateAlerts({
        message: e?.message || String(e),
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const returnSells = () => {
    const ownSells = [];
    const filteredSells = {};
    let arrayKeySells = [];

    Object.keys(sells).forEach((priceKey) => {
      filteredSells[priceKey] = sells[priceKey].filter((sell) => {
        if (state?.uid && sell?.resaleBookedBy === state.uid) {
          ownSells.push(sell);
          return false;
        }
        return true;
      });
    });

    arrayKeySells = Object.keys(filteredSells).filter(
      (priceKey) => filteredSells[priceKey]?.length > 0
    );

    if (ownSells.length > 0) {
      return (
        <>
          <p className={styles.labelEmptySells}>
            {t("components.contentEvent.yet")}{" "}
            <b>{t("components.contentEvent.havePendingTransactions")}</b>{" "}
            {t("components.contentEvent.retakeProcessFinish")}
          </p>
          <button
            className={styles.buySellButton}
            onClick={() => getResaleProcess(ownSells)}
          >
            {t("components.contentEvent.resum")}
          </button>
        </>
      );
    }

    if (arrayKeySells.length < 1) {
      return (
        <>
          <p className={styles.labelEmptySells}>
            {t("components.contentEvent.anyUserPublish")}
            <br />
            <br />
            {vc.interested ? (
              <>
                <b>{t("components.contentEvent.alreadyFollowEvent")}</b>
                {t("components.contentEvent.youCanCancelSuscription")}
              </>
            ) : (
              <>
                <b>{t("components.contentEvent.comeOnFollow")}</b>
                {t("components.contentEvent.thisEventReceiveNotifications")}
              </>
            )}
          </p>
        </>
      );
    }

    return Object.keys(sells).map((keySell) => (
      <div key={keySell} className={styles.rowSell}>
        <div>
          {currencyFormat(
            keySell,
            "COP",
            state.defaultCurrency === "USD" ? 2 : 0
          )}
        </div>
        <div>{sells[keySell]?.length}</div>
        <div style={{ flex: 1 }} />
        <div className={styles.quantitySell}>
          <button
            className={[
              styles.chevButton,
              (quantityPriceSell?.[keySell] || 0) <= 0 ? styles.opacity : "",
            ].join(" ")}
            onClick={() =>
              changeQuantityPriceSell(keySell, -1, sells[keySell]?.length)
            }
          >
            <ChevronLeft />
          </button>
          <div className={styles.numberQuantitySell}>
            {quantityPriceSell?.[keySell] || 0}
          </div>
          <button
            className={[
              styles.chevButton,
              (quantityPriceSell?.[keySell] || 0) >= sells[keySell]?.length
                ? styles.opacity
                : "",
            ].join(" ")}
            onClick={() =>
              changeQuantityPriceSell(keySell, 1, sells[keySell]?.length)
            }
          >
            <ChevronRight />
          </button>
        </div>
      </div>
    ));
  };

  const onRedirectToSells = () => {
    Model.setData("dialog", {
      open: true,
      title: "Antes de continuar ...",
      text: "Serás redirigido al proveedor de reeventas en www.fravents.com, ¿deseas avanzar?",
      txtLeft: t("commons.cancel"),
      fnLeft: () => {
        Model.setData("dialog", { open: false });
      },
      txtRight: "Ir a Fravents",
      fnRight: () => {
        Model.setData("dialog", { open: false });
        history.push(`/events?event=${eventInfo.uid}#sellOffer`);
      },
    });
  };

  const returnSellsList = () => {
    if (hideSellOffers) {
      return (
        <div
          ref={sellContainerRef}
          className={styles.grayContainer}
          id="sellOffer"
        >
          <p className={styles.labelGray}>
            {t("components.contentEvent.sales")}
          </p>
          <Tooltip
            title={t("components.contentEvent.ticketsOnListMembersResell")}
          >
            <p className={styles.labelGrayCenter}>
              {t("components.contentEvent.resellOffers")}{" "}
              <InfoOutlined
                className={styles.iconInfoSell}
                onClick={clickOnInfoIcon}
              />
            </p>
          </Tooltip>

          <button className={styles.buySellButton} onClick={onRedirectToSells}>
            Consultar reventas
          </button>
        </div>
      );
    }

    return parseInt(eventInfo.date) < parseInt(moment().format("x")) ||
      eventInfo?.isCancelled ? null : (
      <div
        ref={sellContainerRef}
        className={styles.grayContainer}
        id="sellOffer"
      >
        <p className={styles.labelGray}>{t("components.contentEvent.sales")}</p>
        <Tooltip
          title={t("components.contentEvent.ticketsOnListMembersResell")}
        >
          <p className={styles.labelGrayCenter}>
            {t("components.contentEvent.resellOffers")}{" "}
            <InfoOutlined
              className={styles.iconInfoSell}
              onClick={clickOnInfoIcon}
            />
          </p>
        </Tooltip>
        <div className={styles.headerSells}>
          <div>{t("commons.price")}</div>
          <div>{t("commons.availables")}</div>
          <div>{t("commons.quantity")}</div>
        </div>
        {returnSells()}

        {Object.keys(quantityPriceSell).filter(
          (keyPrice) => quantityPriceSell[keyPrice] > 0
        ).length > 0 ? (
          <p className={styles.taxesTextSells}>
            {t("components.contentEvent.taxPlatform")} {returnTotalCommission()}{" "}
            {state.defaultCurrency || "COP"}
            <br />
            <b>Total: </b>
            {returnCurrenyTotalSells()} {state.defaultCurrency || "COP"}
          </p>
        ) : null}

        {Object.keys(quantityPriceSell).filter(
          (keyPrice) => quantityPriceSell[keyPrice] > 0
        ).length > 0 ? (
          <button className={styles.buySellButton} onClick={onSellTickets}>
            {t("commons.buy")}
          </button>
        ) : (
          <button
            className={[
              styles.buySellButton,
              vc.interested ? styles.bgRed : "",
            ].join(" ")}
            onClick={onBook}
          >
            {vc.interested
              ? t("components.contentEvent.stopFollowOffers")
              : t("components.contentEvent.followOffers")}
          </button>
        )}
      </div>
    );
  };

  React.useEffect(() => {
    if (idsPreferences?.length === 1) {
      openTransaction(0);
    }
    // eslint-disable-next-line
  }, [idsPreferences]);

  React.useEffect(() => {
    // console.log(scrollYvalue, sellContainerRef?.current?.offsetWidth);
    if (scrollYvalue - 60 > sellContainerRef?.current?.offsetTop) {
      setShowFloatSellLabel(false);
    } else {
      setShowFloatSellLabel(true);
    }
  }, [scrollYvalue]);

  React.useEffect(() => {
    if (clickOnInfo.quantity === 22 && wantsSeeEasteregg) {
      window.open("https://www.youtube.com/watch?v=eiN0LE4abxs", "blank");
    }
  }, [clickOnInfo, wantsSeeEasteregg]);

  React.useEffect(() => {
    if (isBookmarked(eventInfo?.uid, state.bookmarks)) {
      changeVc({ interested: true });
    } else {
      changeVc({ interested: false });
    }
    //eslint-disable-next-line
  }, [state.bookmarks]);

  return (
    <>
      <MercadoPagoScript
        onEndLoad={() => {
          setMercadoPago(
            new window.MercadoPago(keyMp, {
              locale: "es-CO",
            })
          );
        }}
      />

      <div className={returnWithMobileStyles(styles.container)}>
        <div className={returnWithMobileStyles(styles.leftContainer)}>
          <h6 className={styles.label}>{t("commons.artists")}</h6>
          <div className={returnWithMobileStyles(styles.rowArtists)}>
            {renderArtists()}
          </div>
          <div className={styles.blank} />
          <p className={styles.label}>{t("commons.description")}</p>
          <p className={styles.description}>{eventInfo.description}</p>
          {eventInfo?.imageDescription ? (
            <img
              alt="description"
              src={eventInfo?.imageDescription}
              className={styles.imageDescription}
            />
          ) : null}
          {eventInfo.showPromoters ? (
            <>
              <h6 className={styles.label}>{t("commons.promoters")}</h6>
              <div className={returnWithMobileStyles(styles.rowArtists)}>
                {renderContacts()}
              </div>
            </>
          ) : null}
          <div className={styles.blank} />
          <p className={styles.label}>
            {t("components.contentEvent.termsConditionsEvent")}
          </p>
          <p className={styles.description}>
            {eventInfo.terms}
            <br />
            <br />-{" "}
            <em>{t("components.contentEvent.conditionEventFravents")}</em>
          </p>
          <div className={styles.blank} />
        </div>
        <div className={returnWithMobileStyles(styles.rightContainer)}>
          <div className={styles.grayContainer}>
            <p className={styles.labelGray}>
              {t("components.contentEvent.ticketPrices")}
            </p>
            {renderPrices()}
            <div className={styles.blank} />
            {coords?.lat && coords?.lon && !eventInfo?.hideAddress ? (
              <>
                <p className={styles.labelGray}>
                  {t("components.contentEvent.map")}
                </p>
                <a
                  href={`https://maps.google.com/?ll=${coords.lat},${coords.lon}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={styles.contMap}>
                    <img
                      src={`https://maps.googleapis.com/maps/api/staticmap?center=${coords.lat},${coords.lon}&zoom=17&size=500x500&maptype=roadmap&markers=color:red%7C${coords.lat},${coords.lon}&key=AIzaSyDOwLfXIhYWuijRswZexJe5d0JDZPBM7dY&callback=Function.prototype`}
                      title=""
                      alt="Map"
                    />
                  </div>
                </a>
              </>
            ) : null}
          </div>

          {returnSellsList()}
        </div>
      </div>

      {eventInfo?.organizer?.moreEvents?.length > 0 ? (
        <div className={returnWithMobileStyles(styles.containerRowMoreEvents)}>
          <p className={styles.label}>
            {t("components.contentEvent.moreOffers")}
          </p>
          <div className={styles.rowEvents}>
            {eventInfo.organizer.moreEvents.map((item) => (
              <ItemEvent
                key={"item-list-event-more-" + item.uid}
                item={item}
                onClick={() => {
                  Model.setData("eventSelected", item);
                  if (history) {
                    history.push(
                      hideSellOffers ? Routes.PAYMENT_GATEWAY : Routes.EVENTS
                    );
                  }
                }}
                callback={() => {}}
              />
            ))}
          </div>
          <div className={styles.blank} />
        </div>
      ) : null}

      <DialogForm
        show={vc.showPrompt && !state.loading}
        title={t("components.contentEvent.completeSellData")}
        text={t("components.contentEvent.toContinueFillData", {
          quantity: vc.quantity,
          multiples: vc.quantity > 1 ? "s" : "",
        })}
        subtext={t("components.contentEvent.sendDataAcceptTerms")}
        leftBtText={t("commons.cancel")}
        funcLeft={() => changeVc(initialValues)}
        rightBtText={t("commons.accept")}
        funcRight={onSendForm}
        inputs={[
          { label: t("commons.name"), value: "name" },
          { label: t("commons.lastName"), value: "lastName" },
          {
            label: t("commons.email"),
            value: "email",
            type: "email",
            disabled: true,
          },
          { label: t("commons.numId"), value: "numId" },
        ]}
        vc={vc}
        changeVc={changeVc}
        subtextLink={returnCurrentLink(
          Routes.RENDER_LEGAL_DOC + "?document=resale"
        )}
      />

      {Object.keys(sells).length > 0 &&
      showFloatSellLabel &&
      parseInt(eventInfo.date) > parseInt(moment().format("x")) &&
      !eventInfo?.isCancelled ? (
        <a className={styles.anchorSellsFloat} href="#sellOffer">
          <div className={styles.labelSellsFloat}>
            <span>{t("components.contentEvent.sellsInSale")}</span>
            <div />
          </div>
        </a>
      ) : null}

      {idsPreferences.length > 1 ? (
        <div className={styles.modalPreferences}>
          <div className={styles.cardPreferences}>
            <p className={styles.titleCardPreferences}>
              {t("components.contentEvent.selectTransaction")}
            </p>
            <div className={styles.rowPreferences}>
              {idsPreferences.map((idPreference, index) => (
                <button
                  key={idPreference?.value || idPreference}
                  className={styles.buttonPrefence}
                  onClick={() => openTransaction(index)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      ) : null}

      <div className="mercado-pago-container" />
    </>
  );
};

export default ContentEvent;
