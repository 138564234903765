import { create } from "apisauce";
import { authService } from "./firebase";
import { currentLang } from "../utils/validations";

const api = create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3001"
      : window?.location?.host?.includes?.("test.")
      ? "https://fravents-test.uc.r.appspot.com"
      : "https://fravents.uc.r.appspot.com",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 20000,
});

api.axiosInstance.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    const currentUser = authService.currentUser();
    config.headers["x-tenant"] = window.location.hostname;

    if (currentUser) {
      const token = await currentUser.getIdToken();

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["Lang"] = currentLang();
      }
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.axiosInstance.interceptors.response.use(undefined, async (error) => {
  const currentUser = authService.currentUser();
  const errorResponse = error.response;

  if (
    errorResponse &&
    errorResponse.status === 401 &&
    !!error.config &&
    typeof error.config.canRetry === "undefined"
  ) {
    if (currentUser) {
      const token = await currentUser.getIdToken(true);
      error.config.headers["Authorization"] = `Bearer ${token}`;
      error.config.headers["Lang"] = currentLang();
      // console.warn('retry config headers =>', error.config.headers)
      error.config.canRetry = false;
      return api.axiosInstance.request(error.config);
    }
  }
  throw error;
});

export const axios = api.axiosInstance;

export default api;
