import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Instagram, Facebook, WhatsApp, Email } from "@material-ui/icons";
import moment from "moment";

import pageStyles from "./styles/companySocialMediaLinksStyles";
import EventsService from "../../services/events";
import { Store } from "../../hooks/main_store";
import * as Routes from "../../utils/routes";
import Model from "../../hooks/Model";

const CompanySocialMediaLinks = ({ history }) => {
  const { t } = useTranslation();
  const { state } = useContext(Store);
  const styles = pageStyles();

  const mainEvent = state?.systemSettings?.eventsBanner
    ?.map(
      (item) =>
        state.events[
          state.events.findIndex((itemInfo) => itemInfo.uid === item)
        ]
    )
    .filter((item) => !!item)?.[0];

  const getEvents = async () => {
    try {
      Model.setData("loading", !(state?.events?.length > 0));
      await EventsService.getEvents();
    } catch (e) {
      console.log(t("pages.events.allEvents.errorGetEvents"), e);
    } finally {
      Model.setData("loading", false);
    }
  };

  const goTo = (route) => {
    switch (route) {
      case "instagram":
        window.location = "https://instagram.com/fravents";
        break;
      case "facebook":
        window.location = "https://facebook.com/fravents";
        break;
      case "whatsapp":
        window.location = "https://wa.me/573012069045";
        break;
      case "email":
        window.location = "mailto:central@fravents.com";
        break;
      case "events":
        Model.setData("eventSelected", mainEvent);
        history.push(Routes.EVENTS);
        break;
      case "froin":
        window.location = "https://crypto.fravents.com/account/vwin";
        break;
      default:
        history.push(route || Routes.DASHBOARD);
    }
  };

  React.useEffect(() => {
    Model.setData("opacity", 0.7);
    getEvents();
    return () => {};
    //eslint-disable-next-line
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.scrollContainer}>
        <h1 className={styles.title}>@fravents</h1>
        <Grid
          container
          wrap="nowrap"
          justifyContent="space-around"
          style={{ paddingBottom: 30 }}
        >
          <Grid item>
            <button
              onClick={() => goTo("instagram")}
              className={styles.iconSocial}
            >
              <Instagram />
            </button>
          </Grid>
          <Grid item>
            <button
              onClick={() => goTo("facebook")}
              className={styles.iconSocial}
            >
              <Facebook />
            </button>
          </Grid>
          <Grid item>
            <button
              onClick={() => goTo("whatsapp")}
              className={styles.iconSocial}
            >
              <WhatsApp />
            </button>
          </Grid>
          <Grid item>
            <button onClick={() => goTo("email")} className={styles.iconSocial}>
              <Email />
            </button>
          </Grid>
        </Grid>

        {process.env.NODE_ENV === "development" && (
          <button className={styles.buttonLink} onClick={() => goTo("froin")}>
            {"🤑"} Gana viendo videos
          </button>
        )}
        <button
          className={styles.buttonLink}
          onClick={() => goTo(Routes.PROGRAM_INFO)}
        >
          {"🤓"} Buscamos promotores
        </button>
        <button
          className={styles.buttonLink}
          onClick={() => goTo(Routes.CONTACT_PUBLISH)}
        >
          {"🔥"} Promovemos tu evento
        </button>
        {mainEvent && (
          <button className={styles.mainEvent} onClick={() => goTo("events")}>
            <img
              alt="banner"
              className={styles.imageMainEvent}
              src={mainEvent?.imageBanner}
            />
            <div className={styles.infoMainEvent}>
              <p className={styles.titleMainEvent}>{mainEvent?.name}</p>
              <p className={styles.descriptionMainEvent}>
                {mainEvent?.description?.slice(0, 120)}
              </p>
              <p className={styles.dateMainEvent}>
                {moment(mainEvent?.date, "x").format("DD MMM, YYYY hh:mm a")}
              </p>
            </div>
          </button>
        )}
        <button
          className={styles.buttonLink}
          onClick={() => goTo(Routes.ALL_EVENTS)}
        >
          {"🎉"} Ver más eventos
        </button>
        <div className={styles.footer}>
          Fravents © 2025 - v. {state.version} {"🇨🇴"} Hecho en Colombia
        </div>
      </div>
    </div>
  );
};

export default CompanySocialMediaLinks;
