import React from "react";
import {
  Visibility,
  Close,
  CheckCircle,
  ArrowUpward,
  ArrowDownward,
  ChevronLeft,
  ChevronRight,
  AddCircle,
  Clear,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";

import {
  sizeImage,
  currencyFormat,
  moveItemsArray,
} from "../../utils/validations";
import DetailProduct from "../../pages/shop/detailProduct";
import { Store } from "../../hooks/main_store";
import ShopService from "../../services/shop";
import Input from "../../components/input";
import Model from "../../hooks/Model";
import Layout from "../layout";

import pageStyles from "./styles/eventFormStyles";

const ProductForm = ({ history }) => {
  const { t } = useTranslation();
  const { state } = React.useContext(Store);
  const styles = pageStyles();
  const beforeData = history?.location?.state?.draftProduct;

  const toEdit = !!beforeData;
  const steps = [
    {
      label: t("pages.admin.productForm.generalData"),
      button: t("pages.admin.productForm.data"),
    },
    {
      label: t("pages.admin.productForm.pricesTc"),
      button: t("pages.admin.productForm.prices"),
    },
    {
      label: t("pages.admin.productForm.configuration"),
      button: t("pages.admin.productForm.configuration"),
    },
  ];

  const inputImagesRef = React.useRef();
  const [selectedStep, setSelectedStep] = React.useState(0);
  const [showPreview, setShowPreview] = React.useState(false);
  const [pendingPhotos, setPendingPhotos] = React.useState([]);
  const [vc, setVc] = React.useState({
    images: [],
    includeShipment: null,
    name: "",
    status: "",
    terms: "",
    prices: [],
    description: "",
    newPriceLabel: "",
    newPriceValue: "",
    newPriceStock: "",
    fbPx: "",
    showFbPixel: false,
    errors: {},
  });
  const changeVc = (data) => setVc((ov) => ({ ...ov, ...data }));

  const changeState = (name) => (event) => {
    let valueInput = "";
    event.persist();

    switch (name) {
      case "newPriceStock":
        valueInput = event.target.value.replace(".", "");
        break;
      default:
        valueInput = event.target.value;
    }
    changeVc({
      [name]: valueInput,
    });
  };

  const changeStock = (index) => (event) => {
    const newDataPrices = [...vc.prices];
    newDataPrices[index].stock = parseInt(event.target.value);
    event.persist();
    changeVc({
      prices: newDataPrices,
    });
  };

  const validateForm = (callback = () => {}, forceSave = false) => {
    const auxPrices = vc.prices;
    const setErrors = () => {
      changeVc({
        errors: {
          name: !vc.name,
          prices: auxPrices.length <= 0,
          includeShipment: vc.includeShipment === null,
          terms: !vc.terms,
          status: !vc.status,
          images: vc.images.length < 1,
        },
      });
      Model.updateAlerts({
        message: t("pages.admin.productForm.enterAllData"),
        variant: "error",
      });
    };

    changeVc({ errors: {} });

    if (
      vc.name &&
      vc.images.length > 0 &&
      auxPrices.length > 0 &&
      vc.includeShipment !== null &&
      vc.terms &&
      vc.status &&
      forceSave
    ) {
      return callback();
    }

    switch (selectedStep) {
      case 0:
        if (vc.name && vc.images.length > 0) {
          if (forceSave) {
            callback();
          } else {
            setSelectedStep((ov) => ov + 1);
          }
        } else {
          setErrors();
        }
        break;
      case 1:
        if (auxPrices.length > 0 && vc.terms) {
          if (forceSave) {
            callback();
          } else {
            setSelectedStep((ov) => ov + 1);
          }
        } else {
          setErrors();
        }
        break;
      case 2:
        if (vc.status && vc.includeShipment !== null) {
          callback();
        } else {
          setErrors();
        }
        break;
      default:
        setSelectedStep(1);
    }
  };

  const popPrice = (index) => {
    const auxItems = [...vc.prices];
    auxItems.splice(index, 1);
    changeVc({ prices: auxItems });
  };

  const movePrice = (index, nextPosition) => {
    const auxItems = moveItemsArray(vc.prices, index, index + nextPosition);
    changeVc({ prices: auxItems });
  };

  const onAddPrice = () => {
    if (vc.newPriceValue < 1000) {
      Model.updateAlerts({
        message: t("pages.admin.productForm.priceMajorCop"),
        variant: "error",
      });
    } else if (vc.newPriceLabel && vc.newPriceValue && vc.newPriceStock) {
      const auxItems = [...vc.prices];

      if (
        auxItems.filter((item) => item.label === vc.newPriceLabel).length > 0
      ) {
        return Model.updateAlerts({
          message: t("pages.admin.productForm.nameMustBeDifferent"),
          variant: "error",
        });
      }

      auxItems.push({
        label: vc.newPriceLabel,
        price: parseFloat(parseFloat(vc.newPriceValue).toFixed(2)),
        stock: parseInt(vc.newPriceStock),
      });
      changeVc({
        prices: auxItems,
        newPriceLabel: "",
        newPriceValue: "",
        newPriceStock: "",
      });
    } else {
      Model.updateAlerts({
        message: t("pages.admin.productForm.enterAllPriceData"),
        variant: "error",
      });
    }
  };

  const renderPrices = () => {
    return (
      <>
        <div className={styles.contPrices}>
          {vc.prices.length > 0
            ? vc.prices.map((price, index) => (
                <div
                  key={"item-price-" + index}
                  className={styles.rowInfoPrice}
                >
                  <p className={styles.textInfoPrice}>{price?.label}</p>
                  <p className={styles.textInfoPrice}>
                    {currencyFormat(price?.price, "COP", 0)} COP
                  </p>

                  {toEdit ? (
                    <Input
                      customStyles={styles.inputPrice}
                      placeholder="Cantidad disponible"
                      onChange={changeStock(index)}
                      value={String(parseInt(price.stock))}
                      type="number"
                    />
                  ) : (
                    <p className={styles.textInfoPrice}>
                      {price?.stock} {t("pages.admin.productForm.available")}
                    </p>
                  )}

                  {index <= 0 ? null : (
                    <button
                      className={[
                        styles.buttonMoveItem,
                        styles.buttonMoveUpItem,
                      ].join(" ")}
                      onClick={() => movePrice(index, -1)}
                    >
                      <ArrowUpward />
                    </button>
                  )}
                  {index + 1 >= vc.prices.length ? null : (
                    <button
                      className={[
                        styles.buttonMoveItem,
                        styles.buttonMoveDownItem,
                      ].join(" ")}
                      onClick={() => movePrice(index, 1)}
                    >
                      <ArrowDownward />
                    </button>
                  )}

                  {toEdit ? (
                    <button
                      className={[
                        styles.buttonInfoPrice,
                        price.stock > 0 ? styles.gray : "",
                      ].join(" ")}
                      onClick={() => {
                        const newDataPrices = [...vc.prices];
                        newDataPrices[index].stock = 0;
                        changeVc({
                          prices: newDataPrices,
                        });
                      }}
                    >
                      {price.stock > 0
                        ? t("pages.admin.productForm.empty")
                        : t("pages.admin.productForm.soldOut")}
                    </button>
                  ) : null}

                  <button
                    className={styles.buttonInfoPrice}
                    onClick={() => popPrice(index)}
                  >
                    <Close />
                  </button>
                </div>
              ))
            : null}
          <div className={styles.rowInputPrice}>
            <Input
              customStyles={styles.inputPrice}
              placeholder={t("pages.admin.productForm.sizeColor")}
              onChange={changeState("newPriceLabel")}
              value={vc.newPriceLabel}
            />
            <Input
              customStyles={styles.inputPrice}
              placeholder={t("pages.admin.productForm.priceCop")}
              onChange={changeState("newPriceValue")}
              value={isNaN(parseInt(vc.newPriceValue)) ? "" : vc.newPriceValue}
              type="number"
            />
            <Input
              customStyles={styles.inputPrice}
              placeholder={t("pages.admin.productForm.availableQuantity")}
              onChange={changeState("newPriceStock")}
              value={isNaN(parseInt(vc.newPriceStock)) ? "" : vc.newPriceStock}
              type="number"
            />
            <button className={styles.buttonPrice} onClick={onAddPrice}>
              <CheckCircle />
            </button>
          </div>
        </div>
      </>
    );
  };

  const onPreview = () => {
    setShowPreview((ov) => !ov);
  };

  const onSend = async () => {
    try {
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);
      const uidProduct = await ShopService.createProduct({
        description: vc.description,
        name: vc.name,
        prices: vc.prices,
        includeShipment: vc.includeShipment,
        status: vc.status,
        terms: vc.terms,
        fbPx: vc.fbPx,
      });

      const referenceFile = await ShopService.updateProductImages(
        state.uid,
        uidProduct,
        vc.images,
        pendingPhotos
      );

      await ShopService.updateProduct({
        productUid: uidProduct,
        data: {
          images: referenceFile[0],
        },
      });

      Model.setData("productSelected", null);
      Model.updateAlerts({
        message: t("pages.admin.productForm.successCreateProduct"),
        variant: "success",
      });
      history.push("/admin-shop?tab=1");
    } catch (e) {
      Model.updateAlerts({
        message: `${t("pages.admin.productForm.errorCreateProduct")}:  ${e}`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onEdit = async () => {
    try {
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);

      const referenceFile = await ShopService.updateProductImages(
        state.uid,
        beforeData.uid,
        vc.images,
        pendingPhotos
      );

      await ShopService.updateProduct({
        productUid: beforeData.uid,
        data: {
          description: vc.description,
          name: vc.name,
          prices: vc.prices,
          includeShipment: vc.includeShipment,
          status: vc.status,
          terms: vc.terms,
          fbPx: vc.fbPx,
          images: referenceFile[0],
        },
      });

      Model.setData("productSelected", null);
      Model.updateAlerts({
        message: t("pages.admin.productForm.successSaveProduct"),
        variant: "success",
      });
      history.push("/admin-shop?tab=1");
    } catch (e) {
      Model.updateAlerts({
        message: `${t("pages.admin.productForm.errorSaveProduct")}:  ${e}`,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const beforeSend = () => {
    Model.setData("dialog", {
      open: true,
      title: t("pages.admin.productForm.saveProduct"),
      text: t("pages.admin.productForm.areYouSureSaveProduct"),
      txtLeft: t("commons.no"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.yes"),
      fnRight: beforeData?.uid ? onEdit : onSend,
    });
  };

  const moveImage = (index, nextPosition) => {
    const auxItems = moveItemsArray(vc.images, index, index + nextPosition);
    changeVc({ images: auxItems });
  };

  const removeImage = (index) => {
    let auxItems = [...vc.images];
    auxItems.splice(index, 1);
    changeVc({ images: auxItems });
  };

  const addImage = (newImage) => {
    let auxItems = [...vc.images];
    auxItems.push(newImage);
    changeVc({ images: auxItems });
  };

  const renderRowImages = () => (
    <div className={styles.rowImages}>
      {vc.images.map((image, index) => (
        <div
          key={image?.preview || image}
          style={{ backgroundImage: `url(${image?.preview || image})` }}
          className={styles.imageProduct}
        >
          <button className="delete-btn" onClick={() => removeImage(index)}>
            <Clear />
          </button>
          <div className={styles.rowMoveImgProduct}>
            {index > 0 ? (
              <button onClick={() => moveImage(index, -1)}>
                <ChevronLeft />
              </button>
            ) : null}
            {index < vc.images.length - 1 ? (
              <button onClick={() => moveImage(index, 1)}>
                <ChevronRight />
              </button>
            ) : null}
          </div>
        </div>
      ))}
      {vc.images.length >= 5 ? null : (
        <Tooltip arrow title={t("pages.admin.productForm.maxFileSize")}>
          <div className={styles.uploadImgButton}>
            <input
              className={styles.fileInput}
              type="file"
              accept="image/*"
              ref={inputImagesRef}
              onChange={(e) => {
                sizeImage(e.target.files[0], 5 * 1024 * 1024, () => {
                  const reader = new FileReader();
                  const urlLocal = e.target.files[0];
                  reader.onload = () => {
                    addImage(reader.result);
                    setPendingPhotos((ov) => [
                      ...ov,
                      { preview: reader.result, data: urlLocal },
                    ]);
                  };
                  reader.readAsDataURL(e.target.files[0]);
                });
              }}
            />
            <button onClick={() => inputImagesRef?.current?.click()}>
              <AddCircle />
            </button>
          </div>
        </Tooltip>
      )}
    </div>
  );

  const renderForm = () => {
    if (selectedStep === 0) {
      return (
        <>
          <p
            className={[styles.labelInput, vc.errors?.name ? "error" : ""].join(
              " "
            )}
          >
            Nombre del producto *
          </p>
          <Input
            fullWidth
            customStyles={styles.textInput}
            placeholder={t("pages.admin.productForm.whatIsProductName")}
            onChange={changeState("name")}
            value={vc.name}
          />

          <div className={styles.row}>
            <div className={styles.itemRowForm}>
              <p
                className={[
                  styles.labelInput,
                  vc.errors?.imageBanner ? "error" : "",
                ].join(" ")}
              >
                {t("pages.admin.productForm.images")} *
              </p>

              {renderRowImages()}
            </div>
          </div>

          <p className={styles.labelInput}>
            {t("pages.admin.productForm.description")}
          </p>
          <textarea
            className={styles.textArea}
            placeholder={t("pages.admin.productForm.tellMoreAbout")}
            onChange={changeState("description")}
            value={vc.description}
          />
        </>
      );
    }
    if (selectedStep === 1) {
      return (
        <>
          <p
            className={[
              styles.labelInput,
              vc.errors?.terms ? "error" : "",
            ].join(" ")}
          >
            {t("pages.admin.productForm.termsConditions")} *
          </p>
          <textarea
            className={styles.textArea}
            placeholder={t("pages.admin.productForm.textAddedTerms")}
            onChange={changeState("terms")}
            value={vc.terms}
          />
          <p
            className={[
              styles.labelInput,
              vc.errors?.prices ? "error" : "",
            ].join(" ")}
          >
            {t("pages.admin.productForm.prices")} *
          </p>
          <p className={styles.sublabelInput}>
            {t("pages.admin.productForm.moneySendToYourAccounts")}
          </p>
          {toEdit || vc.typeTemplate ? null : (
            <p className={styles.labelInput}>
              <br />
              {t("pages.admin.productForm.addNewPrice")}
            </p>
          )}
          {renderPrices()}
        </>
      );
    }
    if (selectedStep === 2) {
      return (
        <>
          <p className={styles.labelInput}>
            {t("pages.admin.productForm.graphicResources")}
          </p>
          <div className={styles.rowButtons}>
            <a
              download="Logo-Fravents-Negro.png"
              className={styles.buttonSourceLogoBlack}
              href={require("../../assets/images/Logo-Fravents-Negro.png")}
            >
              <img
                alt="Fravents"
                src={require("../../assets/images/Logo-Fravents-Negro.png")}
                className={styles.logoSource}
              />
              <p className={styles.labelLogo}>{t("commons.download")}</p>
            </a>
            <a
              download="Logo-Fravents-blanco.png"
              href={require("../../assets/images/Logo-Fravents-blanco.png")}
              className={[styles.buttonSourceLogoBlack, styles.logoWhite].join(
                " "
              )}
            >
              <img
                alt="Fravents"
                src={require("../../assets/images/Logo-Fravents-blanco.png")}
                className={styles.logoSource}
              />
              <p className={styles.labelLogo}>{t("commons.download")}</p>
            </a>
          </div>

          <div className={styles.row}>
            <div className={styles.itemRowForm}>
              <p
                className={[
                  styles.labelInput,
                  vc.errors?.status ? "error" : "",
                ].join(" ")}
              >
                {t("pages.admin.productForm.sellAvailable")} *
              </p>
              <div className={styles.rowButtons}>
                <button
                  className={[
                    styles.buttonRow,
                    vc.status === "published" ? styles.bgGreen : "",
                  ].join(" ")}
                  onClick={() => changeVc({ status: "published" })}
                >
                  {t("commons.yes")}
                </button>
                <button
                  className={[
                    styles.buttonRow,
                    vc.status === "draft" ? styles.bgRed : "",
                  ].join(" ")}
                  onClick={() => changeVc({ status: "draft" })}
                >
                  {t("commons.no")}
                </button>
              </div>
            </div>
            <div className={styles.itemRowForm}>
              <p
                className={[
                  styles.labelInput,
                  vc.errors?.includeShipment ? "error" : "",
                ].join(" ")}
              >
                {t("pages.admin.productForm.sendsIncluded")} *
              </p>
              <div className={styles.rowButtons}>
                <button
                  className={[
                    styles.buttonRow,
                    vc.includeShipment === true ? styles.bgGreen : "",
                  ].join(" ")}
                  onClick={() => changeVc({ includeShipment: true })}
                >
                  {t("commons.yes")}
                </button>
                <button
                  className={[
                    styles.buttonRow,
                    vc.includeShipment === false ? styles.bgRed : "",
                  ].join(" ")}
                  onClick={() => changeVc({ includeShipment: false })}
                >
                  {t("commons.no")}
                </button>
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.itemRowForm}>
              <p className={styles.labelInput}>
                {t("pages.admin.productForm.addFbPixel")}
              </p>
              <div className={styles.rowButtons}>
                <button
                  className={[
                    styles.buttonRow,
                    vc.showFbPixel === true ? styles.bgGreen : "",
                  ].join(" ")}
                  onClick={() => changeVc({ showFbPixel: true, fbPx: "" })}
                >
                  {t("commons.yes")}
                </button>
                <button
                  className={[
                    styles.buttonRow,
                    vc.showFbPixel === false ? styles.bgRed : "",
                  ].join(" ")}
                  onClick={() => changeVc({ showFbPixel: false, fbPx: "" })}
                >
                  {t("commons.no")}
                </button>
              </div>
            </div>
            {vc.showFbPixel ? (
              <div className={styles.itemRowForm}>
                <p className={styles.labelInput}>
                  {t("pages.admin.productForm.fbPixel")}
                </p>
                <Input
                  fullWidth
                  customStyles={styles.textInput}
                  placeholder="1234567890"
                  onChange={changeState("fbPx")}
                  value={vc.fbPx}
                />
              </div>
            ) : null}
          </div>
        </>
      );
    }
  };

  const firstLoad = () => {
    if (beforeData?.uid) {
      setVc({
        ...beforeData,
        newPriceLabel: "",
        newPriceValue: "",
        newPriceStock: "",
        showFbPixel: !!beforeData?.fbPx,
      });
    }
  };

  const loadTestData = () => {
    setVc((ov) => ({
      ...ov,
      images: [
        "https://firebasestorage.googleapis.com/v0/b/fravents-test.appspot.com/o/events%2Fkq2d4YxbqxfLlJZQRClFBbg2YbD3%2FKat4tZ3lvUoZg3sZr3p6%2Fposter.png?alt=media&token=9cc4ee00-b9ff-4d27-bfe9-79d236f038ee",
        "https://firebasestorage.googleapis.com/v0/b/fravents-test.appspot.com/o/events%2FgTORJhrTsrVUc7EfTslaz99eoq02%2FHO6mREfO7qT9T8PT8MoK%2Fposter.png?alt=media&token=7729ee14-0c43-4336-8009-3b89b40e9b37",
      ],
      description: "Lorem ipsum",
      name: "Test product info",
      prices:
        ov.prices.length < 1
          ? [{ label: "Precio 1", price: 30000, stock: 15 }]
          : ov.prices,
      includeShipment: null,
      status: "draft",
      terms: "Long terms test",
      newPriceLabel: "",
      newPriceValue: "",
      newPriceStock: "",
      showFbPixel: false,
    }));
  };

  React.useEffect(() => {
    firstLoad();
    //eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <div className={styles.row}>
        <div className={styles.leftContainer}>
          <div className={styles.rowSteps}>
            {steps.map((step, index) => (
              <button
                key={step.button}
                className={[
                  styles.stepButton,
                  selectedStep === index ? styles.selectedStep : "",
                ].join(" ")}
                onClick={() => setSelectedStep(index)}
              >
                {step.button}
              </button>
            ))}
          </div>
          {process.env.NODE_ENV === "development" ? (
            <button className={styles.loadTestButton} onClick={loadTestData}>
              {t("pages.admin.productForm.loadTestData")}
            </button>
          ) : null}
          <div className={styles.row}>
            <h1 className={styles.titleForm}>{steps[selectedStep].label}</h1>
            <button className={styles.previewButton} onClick={onPreview}>
              <Visibility />
              <span>{t("pages.admin.productForm.preview")}</span>
            </button>
          </div>
          <div className={styles.containerForm}>{renderForm()}</div>
          <div className={styles.rowMoveButtons}>
            {selectedStep > 0 ? (
              <button
                className={styles.beforeButton}
                onClick={() => setSelectedStep((ov) => ov - 1)}
              >
                {t("pages.admin.productForm.before")}
              </button>
            ) : (
              <div />
            )}
            {toEdit && selectedStep < 3 ? (
              <button
                className={styles.nextButton}
                onClick={() => validateForm(beforeSend, true)}
              >
                {t("pages.admin.productForm.save")}
              </button>
            ) : null}
            <button
              className={styles.nextButton}
              onClick={() => validateForm(beforeSend)}
            >
              {selectedStep === 2
                ? t("pages.admin.productForm.save")
                : t("pages.admin.productForm.next")}
            </button>
          </div>
        </div>
        <div
          className={[
            styles.rightContainer,
            showPreview ? styles.showPreview : "",
          ].join(" ")}
        >
          <div className={styles.contPreview}>
            <div className={styles.rowHeaderPreview}>
              <span>{t("pages.admin.productForm.preview")}</span>
              <button onClick={onPreview}>
                <Close />
              </button>
            </div>
            <DetailProduct isPreview previewData={vc} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductForm;
