import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styleguide";
import Commons from "../../utils/styles";

const styles = makeStyles({
  container: {
    border: `6px solid ${colors.themeColor}`,
    borderRadius: "18px !important",
  },
  buttonAux: {
    color: `${colors.gray50} !important`,
    flex: 1,
  },
  buttonCancel: {
    color: `${colors.themeColor} !important`,
    flex: 1,
  },
  buttonDone: {
    backgroundColor: `${colors.themeColor} !important`,
    color: `${colors.white} !important`,
    flex: 1,
  },
  input: {
    width: "100%",
    margin: "6px !important",
    boxSizing: "border-box",
  },
  inputs: {
    width: "calc(50% - 12px)",
    margin: "6px !important",
    boxSizing: "border-box",
    [Commons.smallQuery]: {
      width: "100%",
    },
  },
});

export default styles;
