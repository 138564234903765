import React from "react";
import _ from "lodash";

import { returnSessionStorage as SessionStorage } from "../utils/localStorage";
import IncognitoAlert from "../components/IncognitoAlert";
import { currentLang } from "../utils/validations";
import { authService } from "../services/firebase";
import * as Routes from "../utils/routes";

export const Store = React.createContext();

const version = "2.10.24.0";
let eventsLocal = null;
let eventsPartnerLocal = SessionStorage(false).getItem("eventsPartner");
let myTicketsLocal = SessionStorage(false).getItem("myTickets");
let transactionsHistoryLocal = SessionStorage(false).getItem(
  "transactionsHistory"
);

if (window.location.pathname === Routes.EVENTS) {
  eventsLocal = SessionStorage(false).getItem("events");
  if (!!eventsLocal) {
    eventsLocal =
      JSON.parse(eventsLocal)?.published?.sort?.((a, b) => a.date - b.date) ||
      [];
  }
}

if (!!eventsPartnerLocal) {
  eventsPartnerLocal = JSON.parse(eventsPartnerLocal) || [];
}

if (!!myTicketsLocal) {
  myTicketsLocal = JSON.parse(myTicketsLocal) || [];
}

if (!!transactionsHistoryLocal) {
  transactionsHistoryLocal = JSON.parse(transactionsHistoryLocal) || {};
}

const initialState = {
  loading: true,
  superLoading: true,
  opacity: 1,
  errorLoad: false,
  alerts: [],
  dialog: {
    open: false,
  },
  events: eventsLocal || [],
  draftEvents: [],
  myTickets: myTicketsLocal || [],
  searchedEvents: [],
  eventSelected: null,
  ticketSelected: null,
  productSelected: null,
  openAuth: false,
  bookmarks: [],
  eventsPartner: eventsPartnerLocal || [],
  productsPartner: [],
  teamEventPartner: {},
  salesEventPartner: {},
  infoValidateTicketsEventPartner: {},
  isWhiteLabel: false,
  systemSettings: {
    liveVersion: version,
    eventsBanner: [],
  },
  notification: {
    open: false,
    title: "",
    description: "",
    image: "",
    buttons: [],
  },
  userInfo: {},
  uid: _.get(authService, "currentUser.uid", ""),
  withTabs: false,
  isOnPartner: false,
  isOnPluginPartner: false,
  isIncognito: false,
  emailVerified: false,
  showNavigationEvent: false,
  eventsPartnerPlugin: [],
  eventsCache: {},
  lastGetSettings: "",
  eventTypes: [],
  infoPartner: {},
  selectedEventTemplate: "",
  referStats: {
    earnedQuantity: 0,
    referedQuantity: 0,
    earnedClientsQuantity: 0,
    referedDate: "",
    code2ref: "",
    percentCode2ref: 0,
  },
  shoppingCart: [],
  sellsShopPartner: null,
  sellSelected: null,
  transactionsHistory: transactionsHistoryLocal || [],
  sellsShopReports: {},
  showQrScanModal: false,
  endLoadPrivateRoute: false,
  fistLoad: false,
  defaultLanguage: currentLang(),
  defaultCurrency: "COP", // currentLang() === "en" ? "USD" : "COP",
  moveLayoutScroll: "",
  nequiTransactionId: "",
  version: version,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_STORE":
      return { ...state, [action.label]: action.payload };
    case "UPDATE_USERINFO":
      const newInfo = {
        ...state.userInfo,
        ...action.payload,
      };
      SessionStorage(false).setItem("userInfo", JSON.stringify(newInfo));
      return {
        ...state,
        userInfo: newInfo,
      };
    case "UPDATE_ALERT":
      return {
        ...state,
        alerts: [...state.alerts, action.payload],
      };
    case "RESET_DATA":
      return { ...initialState };
    default:
      return state;
  }
}

export function StoreProvider({ children, isIncognito }) {
  let isOnPluginPartner = false;
  try {
    isOnPluginPartner = window.self !== window.top;
  } catch (e) {
    isOnPluginPartner = true;
  }

  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    isOnPluginPartner,
    isWhiteLabel: isOnPluginPartner,
    isIncognito,
  });
  const value = { state, dispatch };

  if (isIncognito && isOnPluginPartner) {
    return <IncognitoAlert />;
  }

  return <Store.Provider value={value}>{children}</Store.Provider>;
}
